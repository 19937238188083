<header>
  <mat-toolbar
    class="small-screen-toolbar"
    [class.small-screen-toolbar-fullscreen]="isSmallScreen()"
    color="primary"
  >
    <button
      *ngIf="isSmallScreen()"
      mat-icon-button
      (click)="sidenav.toggle()"
      aria-label="Afficher le menu"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <h1
      *ngIf="isSmallScreen() && !sidenav.opened"
      class="logo mobile-logo"
      matTooltip="Alerte Verte"
      aria-label="Alerte Verte"
    >
      <div role="img" aria-hidden="true">
        <span class="logo-a">A</span><span class="logo-v">V</span>
      </div>
    </h1>
  </mat-toolbar>
</header>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    class="sidenav"
    fixedInViewport="true"
    [attr.role]="isSmallScreen() ? 'dialog' : 'navigation'"
    [mode]="isSmallScreen() ? 'over' : 'side'"
    [opened]="!isSmallScreen()"
  >
    <mat-toolbar class="toolbar">
      <h1 class="logo" matTooltip="Alerte Verte" aria-label="Alerte Verte">
        <div role="img" aria-hidden="true">
          <span class="logo-a">A</span><span class="logo-v">V</span>
        </div>
      </h1>
    </mat-toolbar>

    <mat-selection-list
      #categoryList
      [ngModel]="selectedCategoryIds()"
      (ngModelChange)="changeSelectedCategoryIds($event)"
      aria-label="Liste des catégories"
      aria-describedby="category-list-description"
    >
      <mat-list-option
        *ngFor="let category of categories() | keyvalue"
        [value]="category.key"
        [style.color]="category.value.color"
      >
        <div class="list-option-item center-vertically">
          <mat-icon>{{ category.value.icon }}</mat-icon
          >{{ category.value.name }}
        </div>
      </mat-list-option>
    </mat-selection-list>
    <span
      id="category-list-description"
      class="visually-hidden"
      aria-hidden="true"
      >Sélection des catégories d'article à afficher.</span
    >
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <main class="articles">
      <h2 class="visually-hidden">
        {{ currentMonth().toString() | date : "MMMM y" }}
      </h2>
      <a
        *ngFor="let article of articles(); trackBy: trackArticleByUrl"
        class="article-link"
        [href]="article.url"
        rel="author external"
        target="_blank"
      >
        <mat-card
          class="article-card"
          role="article"
          [style.background]="categories()[article.categoryId].color"
        >
          <mat-card-title-group>
            <mat-card-title>
              <h3>{{ article.title }}</h3>
            </mat-card-title>
            <mat-card-subtitle class="article-info" aria-hidden="true">
              <div>
                <span
                  class="article-category"
                  [style.color]="categories()[article.categoryId].color"
                >
                  <mat-icon>{{
                    categories()[article.categoryId].icon
                  }}</mat-icon>
                  {{ categories()[article.categoryId].name }}
                </span>
              </div>
              <address class="article-published-by">
                {{ article.publishedBy }}
              </address>
              <time
                class="article-published-at"
                [attr.datetime]="article.publishedAt"
              >
                {{ article.publishedAt | date : "fullDate" }}
              </time>
            </mat-card-subtitle>
            <span class="visually-hidden">
              Publié dans la catégorie
              {{ categories()[article.categoryId].name }}
              par {{ article.publishedBy }} le
              {{ article.publishedAt | date : "fullDate" }}
            </span>
          </mat-card-title-group>
          <mat-card-content
            class="article-quote"
            [style.margin-bottom]="canShare ? '32px' : 0"
          >
            <blockquote [attr.cite]="article.url">
              {{ article.quote }}
            </blockquote>
          </mat-card-content>
          <mat-card-actions *ngIf="canShare" align="end">
            <button
              mat-icon-button
              (click)="shareArticle(article); (false)"
              matTooltip="Partager cet article"
              aria-label="Partager cet article"
            >
              <mat-icon>share</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </a>
    </main>

    <footer>
      <nav>
        <ul class="reset">
          <li [attr.aria-hidden]="!previousMonth().length">
            <a
              *ngIf="previousMonth().length"
              mat-button
              [routerLink]="previousMonth()"
              rel="prev"
            >
              <mat-icon>arrow_left</mat-icon
              >{{
                previousMonth().toString()
                  | date : (isSmallScreen() ? "MMM y" : "MMMM y")
              }}
            </a>
          </li>
          <li>
            <a
              mat-button
              [routerLink]="currentMonth()"
              class="active"
              aria-current="page"
              disabled
              >{{
                currentMonth().toString()
                  | date : (isSmallScreen() ? "MMM y" : "MMMM y")
              }}</a
            >
          </li>
          <li [attr.aria-hidden]="!nextMonth().length">
            <a
              *ngIf="nextMonth().length"
              mat-button
              [routerLink]="nextMonth()"
              rel="next"
            >
              {{
                nextMonth().toString()
                  | date : (isSmallScreen() ? "MMM y" : "MMMM y")
              }}<mat-icon>arrow_right</mat-icon>
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
